import { API } from "@project/shared"

export interface IReturnConsultation {
  count?: number
  data?: any
}

interface IConsultationQueryParam {
  page?: string
  pageSize?: string
  sort?: string
  created_at?: any
  user_name?: string
  admin?: string
}

export const fetchConsultationMemo = async ({
  queryKey,
}): Promise<IReturnConsultation> => {
  const params = {} as IConsultationQueryParam
  params.page = queryKey[1].page
  params.sort = "updated_at DESC"
  params.pageSize = "10"
  params.created_at = queryKey[1].created_at
  params.user_name = queryKey[1].user_name
  params.admin = queryKey[1].admin

  return API.get(`/consultations`, {
    params,
  })
}

export const getConsultationMemo = async (id) => {
  return API.get(`/consultations/${id}`)
}

export const createConsultationMemo = async (values): Promise<any> => {
  return API.post(`/consultations`, values)
}

export const updateConsultationMemo = async (values) => {
  const id = values.id
  delete values?.id
  return API.put(`/consultations/${id}`, values)
}

export const deleteConsultationMemoFile = async (values): Promise<any> => {
  return API.delete(`/utils/delete-file`, { data: values })
}
