import { API } from "@project/shared"

export interface IReturnInquiries {
  count?: number
  data?: any
}

interface IInquiryQueryParam {
  page?: string
  pageSize?: string
  name?: string
  email?: string
}

export const fetchInquiries = async ({
  queryKey,
}): Promise<IReturnInquiries> => {
  const params = {} as IInquiryQueryParam
  params.page = queryKey[1].page
  params.pageSize = "10"
  params.name = queryKey[1].name
  params.email = queryKey[1].email
  return API.get(`/inquiries`, {
    params,
  })
}

export const getInquiry = async (id) => {
  return API.get(`/inquiries/${id}`)
}

export const createInquiry = async (values): Promise<any> => {
  return API.post(`/inquiries`, values)
}

export const updateInquiry = async (values) => {
  const id = values.id
  return API.put(`/inquiries/${id}`, { memo: values.memo })
}
