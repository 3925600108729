import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import styled from "styled-components"
import {
  TextField,
  Button,
  theme,
  Loader,
  Content,
  TitleWrapper,
  HalfDiv,
  Required,
  PasswordInputBox,
  ButtonWrapper,
  auth,
} from "@project/shared"
import { useContext } from "react"
import { notification } from "antd"
import { getAdmin, updateAdmin } from "../../../services"
import { useMutation, useQuery } from "react-query"
import { AuthContext } from "../../../utils"
import { signOut } from "firebase/auth"

interface Props {
  id?: string | string[]
}
const LoaderWrapper = styled.div`
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ChangePasswordForm: React.FC<Props> = () => {
  const { t } = useTranslation()
  const { loginId, setUser } = useContext(AuthContext)
  const {
    data: adminData,
    isLoading,
    isFetching,
  } = useQuery<any, Error>(["self-id", loginId], () => getAdmin(loginId), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    enabled: !!loginId,
    select: ({ data }) => {
      return {
        id: data.id || loginId,
        name: data.name,
        email: data.email,
        password: data.password,
        phone: data.phone,
        address: data.address,
        department: data.department,
        admin_type: data.admin_type,
        is_enabled: data.is_enabled,
        fid: data.firebase_uid,
      }
    },
  })

  const initialValues = adminData || {
    password: "",
  }

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(6, t("Password must be atleast 6 characters"))
      .nullable(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate(values)
    },
  })

  const {
    mutate,
    isLoading: isMutating,
    // isSuccess,
  } = useMutation(updateAdmin, {
    onSuccess: () => {
      notification.success({
        message: t("Password updated successfully. Please login again"),
      })
      signOut(auth)
      setUser(null)
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("An error has occurred. Please try again later."),
      })
    },
  })
  if (isLoading || isFetching) {
    return (
      <LoaderWrapper>
        <Loader size="large" />
      </LoaderWrapper>
    )
  }
  return (
    <>
      <Content>
        <TitleWrapper>{t("Password update")}</TitleWrapper>

        <div className={"div-wrapper"}>
          <HalfDiv>
            <div className="label">
              {t("Password")} <Required>{"*"}</Required>
            </div>
            <PasswordInputBox>
              <TextField
                width="256px"
                height="44px"
                name="password"
                className="input"
                borderradius="10px"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : t("Please enter at least 6 characters")
                }
                disableboxshadow
              />
            </PasswordInputBox>
          </HalfDiv>
        </div>

        <ButtonWrapper>
          <Button
            width="120px"
            height="40px"
            background={theme.button.primary}
            onClick={() => formik.handleSubmit()}
            loading={isMutating}
            htmlType="submit"
          >
            {t("Update")}
          </Button>
        </ButtonWrapper>
      </Content>
    </>
  )
}

export { ChangePasswordForm }
