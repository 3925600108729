//esling-disable
import React, { useContext } from "react"
import { Layout, Avatar, Menu } from "antd"
import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"
import { signOut } from "firebase/auth"
import Router, { useRouter } from "next/router"
import { auth, theme } from "@project/shared"
import { AuthContext } from "../../../utils/AuthContext"
import { useGlobalContext } from "../../../context/GlobalContextProvider"
import { toggleSideNavCollapse } from "../../../context/Action"
import { FileTextOutlined } from "@ant-design/icons"

interface SideNavProps {
  disabled?: boolean
}

interface StyledProps {
  isClosed?: boolean
}
const SideBarNavWrapper = styled(Layout.Sider)`
  position: sticky;
  top: 0;
  left: 0;
  background: ${theme.background.navBar.primary};
  height: 100vh;
  .ant-layout-sider-trigger {
    background: ${theme.background.navBar.primary};
    height: 49px;
    border-top: 1px solid #696969;
  }
  .ant-menu-item {
    padding: 0 17px !important;
  }
  .ant-menu-vertical {
    border-right: none !important;
  }
  .ant-menu-item-selected {
    position: relative;
    &::before {
      position: absolute;
      top: 8px;
      left: 0px;
      bottom: 9px;
      border-left: 3px solid rgb(255, 77, 79) !important;
      content: "";
    }
    &::after {
      display: none;
    }
  }
`

const SideNavTopBox = styled.div`
  height: ${(props: StyledProps) => (props.isClosed ? "60px" : "100px")};
  background: ${theme.background.navBar.secondary};
  color: white;
`
const AvatarWrapper = styled(Avatar)`
  ${(props: StyledProps) =>
    props.isClosed
      ? css`
          position: absolute;
          width: 40px;
          height: 40px;
          left: 10px;
          top: 10px;
          background: white;
        `
      : css`
          position: absolute;
          left: 23px;
          top: 22px;
          width: 56px;
          height: 56px;
          background: ${theme.background.secondary};
        `}
`

const AdminTextWrapper = styled.div`
  ${(props: StyledProps) =>
    props.isClosed
      ? css`
          display: none;
        `
      : css`
          position: absolute;
          width: 200px;
          height: 22px;
          left: 101px;
          font-family: ${theme.font.NSJP};
          top: 25px;
          font-weight: ${theme.text.weight.medium};
          font-size: 16px;
          line-height: 16px;
          word-break: break-all;
          padding-right: 66px;
        `}
`
const LogOutTextWrapper = styled.div`
  ${(props: StyledProps) =>
    props.isClosed
      ? css`
          display: none;
        `
      : css`
          position: absolute;
          width: 100%;
          height: 22px;
          left: 101px;
          top: 56px;
          font-family: ${theme.font.NSJP};
          font-weight: ${theme.text.weight.bold};
          font-size: 14px;
          line-height: 22px;
          cursor: pointer;
          text-decoration-line: underline;
        `}
`

const UserIconWrapper = styled.img`
  ${(props: StyledProps) =>
    props.isClosed
      ? css`
          color: ${theme.text.color.dark};
          width: 22px;
          height: 26px;
          object-fit: contain !important;
          padding: 10px;
        `
      : css`
          color: ${theme.text.color.dark};
          width: 22px;
          height: 26px;
          object-fit: contain !important;
          padding: 15px;
        `}
`

const ContentDiv = styled(Menu)`
  background-color: ${theme.background.navBar.primary};

  .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0 !important;
    height: 48px;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
    color: #a2aaba;
    border: 1px solid #696969;
  }
  .ant-menu-item-selected {
    background: rgba(0, 0, 0, 0.2) !important;
    color: white !important;
    margin: 0 !important;
    height: 48px;
  }
`

const LogIcon = styled(FileTextOutlined)`
  svg {
    font-size: 21px;
  }
`
export const SideNavComponent: React.FC<SideNavProps> = () => {
  const { adminRole, setUser } = useContext(AuthContext)
  const store = useContext(AuthContext)

  const { state: globalState, dispatch } = useGlobalContext()
  const routers = useRouter()

  //function for admin name displayed
  const handleAdminName = () => {
    if (store?.adminRole === "super-admin") {
      return t("System admin")
    }
    return store?.user?.displayName
  }
  const handleLocalStorageClear = () => {
    localStorage.removeItem("searchParamsAdmin")
    localStorage.removeItem("searchParamsInquiry")
    localStorage.removeItem("searchParamsUser")
    localStorage.removeItem("searchParamsConsultation")
  }
  const handleSignOut = () => {
    handleLocalStorageClear()
    localStorage.removeItem("filterMemo")
    signOut(auth)
    setUser(null)
  }
  const { t } = useTranslation()
  const menuItem =
    adminRole === "general-admin"
      ? [
          {
            key: "1",
            label: t("Member management"),
            icon: <img src="/assets/icons/ico_users.svg" />,
            onClick: () => {
              Router.push("/user")
              handleLocalStorageClear()
            },
          },
          {
            key: "4",
            label: t("Consultation Memo"),
            icon: <img src="/assets/icons/ico_consultation.svg" />,
            onClick: () => {
              Router.push("/consultation-memo")
              handleLocalStorageClear()
            },
          },
          {
            key: "7",
            label: t("Password update"),
            icon: <LogIcon />,
            onClick: () => {
              Router.push("/password-update")
              handleLocalStorageClear()
            },
          },
        ]
      : [
          {
            key: "1",
            label: t("Member management"),
            icon: <img src="/assets/icons/ico_users.svg" />,
            onClick: () => {
              Router.push("/user")
              handleLocalStorageClear()
            },
          },
          {
            key: "2",
            label: t("Admin List"),
            icon: <img src="/assets/icons/ico_admin.svg" />,
            onClick: () => {
              Router.push("/admin-list")
              handleLocalStorageClear()
            },
          },
          {
            key: "3",
            label: t("IP Management"),
            icon: <img src="/assets/icons/ico_IP.svg" />,
            onClick: () => {
              Router.push("/ip-management")
              handleLocalStorageClear()
            },
          },
          {
            key: "4",
            label: t("Consultation Memo"),
            icon: <img src="/assets/icons/ico_consultation.svg" />,
            onClick: () => {
              Router.push("/consultation-memo")
              handleLocalStorageClear()
            },
          },
          {
            key: "5",
            label: t("User Operation Logs"),
            icon: <LogIcon />,
            onClick: () => {
              Router.push("/operation-logs")
              handleLocalStorageClear()
            },
          },
          {
            key: "6",
            label: t("Inquiry list"),
            icon: <img src="/assets/icons/mail.svg" />,
            onClick: () => {
              Router.push("/inquiry")
              handleLocalStorageClear()
            },
          },
        ]

  /**
   *
   * @param collapse state
   * @returns void
   */
  const collapseStateWatcher = () => {
    dispatch(toggleSideNavCollapse({}))
  }

  const Icon: React.ReactNode = (() => {
    if (globalState?.sideNavCollapse) {
      return <img src="/assets/icons/icon_menu.svg" />
    }
    return <img src="/assets/icons/collapse.svg" />
  })()

  const getNavItemIndexFromPath = (path: string) => {
    if (path.includes("/user")) {
      return "1"
    } else if (path.includes("/admin-list")) {
      return "2"
    } else if (
      path.includes("/ip-management") &&
      adminRole !== "general-admin"
    ) {
      return "3"
    } else if (path.includes("/consultation-memo")) {
      return "4"
    } else if (path.includes("/operation-logs")) {
      return "5"
    } else if (path.includes("/inquiry")) {
      return "6"
    }
  }

  return (
    <SideBarNavWrapper
      collapsible
      collapsed={globalState?.sideNavCollapse}
      width={240}
      onCollapse={collapseStateWatcher}
      collapsedWidth={60}
      trigger={Icon}
    >
      <SideNavTopBox isClosed={globalState?.sideNavCollapse}>
        <AvatarWrapper
          isClosed={globalState?.sideNavCollapse}
          icon={
            <UserIconWrapper
              isClosed={globalState?.sideNavCollapse}
              src="/assets/icons/profile.svg"
            />
          }
        />
        <div className="flex-wrapper">
          <AdminTextWrapper isClosed={globalState?.sideNavCollapse}>
            {handleAdminName()}
          </AdminTextWrapper>
          <LogOutTextWrapper
            isClosed={globalState?.sideNavCollapse}
            onClick={handleSignOut}
          >
            {t("Logout")}
          </LogOutTextWrapper>
        </div>
      </SideNavTopBox>
      <ContentDiv
        mode="inline"
        items={menuItem}
        defaultSelectedKeys={[getNavItemIndexFromPath(routers.pathname)]}
      />
    </SideBarNavWrapper>
  )
}
