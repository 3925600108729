import { TOGGLE_SIDENAV_COLLAPSE, ADD_PAGE_NUMBER_USER } from "./Constants"

export const toggleSideNavCollapse = (data: any) => {
  return {
    type: TOGGLE_SIDENAV_COLLAPSE,
    payload: { data },
  }
}

export const savePageNumberUser = (data: number) => {
  return {
    type: ADD_PAGE_NUMBER_USER,
    payload: { data },
  }
}
