import { API } from "@project/shared"

export interface IReturnIps {
  count?: number
  data?: any
}

interface iIPQueryParam {
  page?: string
  pageSize?: string
}

export const getIps = async ({ queryKey }): Promise<IReturnIps> => {
  const params = {} as iIPQueryParam
  params.page = queryKey[1].page
  params.pageSize = "10"
  return API.get(`/ips`, {
    params,
  })
}

export const updateIps = async (values) => {
  const { id } = values
  return API.put(`/ips/${id}`, values)
}

export const createIps = async (values): Promise<any> => {
  return API.post(`/ips`, values)
}

export const getIpAdminDetail = async (): Promise<IReturnIps> => {
  return API.get(`/ips/latest`)
}

export const deleteIP = async (id) => {
  return API.delete(`/ips/${id}`)
}
