// import { API } from "@project/shared"

import { API } from "@project/shared"

export interface IReturnUserRelations {
  count?: number
  data?: any
}

interface IUserRelationQueryParam {
  page?: string
  pageSize?: string
  role?: string
  user_id?: string
}

export const fetchUserRelations = async ({
  queryKey,
}): Promise<IReturnUserRelations> => {
  const params = {} as IUserRelationQueryParam
  params.page = queryKey[1].page
  params.pageSize = queryKey[1].pageSize
  params.user_id = queryKey[1].user_id
  params.role = queryKey[1].role
  return API.get(`/user-relation`, {
    params,
  })
}
