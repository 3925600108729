import React, { useRef } from "react"
import { Layout } from "antd"
import styled from "styled-components"
import { theme } from "@project/shared/src/theme"
import { Button } from "@project/shared/src/components/atom"
import { t } from "i18next"
import { CSVLink } from "react-csv"
import { useQuery } from "react-query"
import { fetchCsvData } from "@project/shared/src/services"
import { useGlobalContext } from "../../../context/GlobalContextProvider"

/**
 * @props disabled-> to disable button, hasButton -> conditional rendering of button and button text
 * navTitle --> Page title.
 */
interface SideNavProps {
  disabled?: boolean
  hasButton?: boolean
  buttonText?: string
  navTitle?: string
  downloadCSV?: boolean
  csvParams?: any
  onClickButton?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  buttonIcon?: React.ReactNode
  className?: string
}
interface ITopNavWrapper {
  collapsed?: boolean
}
const TopNavWrapper = styled(Layout.Header)<ITopNavWrapper>`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 500;
  font-family: ${theme.font.BUS};
  background: ${theme.background.primary};
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  .csv-link {
    display: none;
  }
  .more-padding {
    padding: 0px 20px !important;
    width: auto;
  }
  height: ${({ collapsed }) => (collapsed ? "60px" : "100px")};
`
const LeftComponent = styled.div`
  display: flex;
  align-items: center;
`

const NavTitleWrapper = styled.div`
  margin: 0 0 0 30px;
  font-weight: ${theme.text.weight.medium};
  font-size: ${theme.text.size.sm};
`

const IconBox = styled.img`
  height: 56px;
  width: 158px;
  margin: 22px 0 22px 0;
`
const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`
export const PageHeaderComponent: React.FC<SideNavProps> = (props) => {
  const {
    hasButton = false,
    buttonText,
    navTitle,
    disabled,
    downloadCSV,
    csvParams,
    onClickButton,
    buttonIcon,
    className,
  } = props

  const csvRef = useRef(null)
  const { state: globalState } = useGlobalContext()
  const {
    data: userCsvData,
    refetch: csvRefetch,
    isFetching: isCsvFetching,
  } = useQuery(["fetchCsvData", csvParams], fetchCsvData, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: false,
    select: (response: any) => {
      if (response?.data) {
        return {
          //just to convert gender to japanese translation
          data: response.data.map((item) => {
            item[6] =
              item[6] === "male"
                ? t("Male")
                : item[6] === "female"
                ? t("Female")
                : item[6]
            return item
          }),
        }
      }
    },
    onSuccess: () => setTimeout(() => csvRef.current?.link?.click(), 0),
  })
  return (
    <TopNavWrapper collapsed={!!globalState?.sideNavCollapse}>
      {/**
       * Need to change path when icon gets ready.
       */}
      <LeftComponent>
        <IconBox src="/assets/imgs/footer-logo.svg" />
        <NavTitleWrapper>{navTitle}</NavTitleWrapper>
      </LeftComponent>
      <ButtonWrapper>
        {downloadCSV && (
          <>
            <CSVLink
              ref={csvRef}
              className="csv-link"
              data={userCsvData?.data || []}
              filename={"会員一覧.csv"}
            />
            <Button
              height="40px"
              width="160px"
              disabled={isCsvFetching}
              margin="0 0 0 auto"
              borderradius="40px"
              background={theme.background.navBar.primary}
              fontSize={theme.text.size.s}
              padding="0px"
              loading={isCsvFetching}
              onClick={() => {
                csvRefetch()
              }}
            >
              {t("Download Csv")}
            </Button>
          </>
        )}
        {hasButton && (
          <Button
            height="40px"
            width="200px"
            disabled={disabled}
            margin="0 0 0 auto"
            borderradius="40px"
            background={"#E26E30"}
            fontSize={theme.text.size.s}
            padding="0px"
            onClick={onClickButton}
            icon={buttonIcon}
            className={className}
          >
            {buttonText}
          </Button>
        )}
      </ButtonWrapper>
    </TopNavWrapper>
  )
}
