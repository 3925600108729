import moment from "moment"
import { API } from "@project/shared"

export interface IReturnUsers {
  count?: number
  data?: any
}

export interface IUserQueryParam {
  page?: string
  pageSize?: string
  keyword?: string
  email?: string
  phone?: string
  created_at?: string
  is_search?: string
}

export const fetchUsers = async ({ queryKey }): Promise<IReturnUsers> => {
  const params = {} as IUserQueryParam
  const dateString: string =
    queryKey[1].registered &&
    moment(queryKey[1].registered).format("YYYY-MM-DD")
  params.page = queryKey[1].page
  params.pageSize = queryKey[1].pageSize
  params.keyword = queryKey[1].name
  params.email = queryKey[1].email
  params.phone = queryKey[1].phone
  params.is_search = queryKey[1].isSearch
  params.created_at = dateString
  return API.get(`/users`, {
    params,
  })
}

export const getUserDetail = async (id: string | string[]) => {
  return API.get(`/users/${id}`)
}
interface ICreateUser {
  id: string | string[]
  name: string
  kana: string
  email: string
  password: string
  phone: string
  emergency_contact: string
  date_of_birth: string
  address: string
  gender: string
  memo: string
}
interface IUpdateUserDetail extends ICreateUser {
  id: string | string[]
}
export const updateUserDetail = async (values: IUpdateUserDetail) => {
  const id = values?.id
  return API.put(`/users/${id}`, values)
}

export const createMember = async (values: ICreateUser) => {
  return API.post(`/users`, values)
}
export const checkUserAvailability = async (values) => {
  return API.post(`/users/check-user`, values)
}
