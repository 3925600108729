import { PlusOutlined } from "@ant-design/icons"
import React, { useEffect, useState } from "react"
import { Upload, notification } from "antd"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { fileUpload } from "../../../services/fileUpload"
import { Button, getSignedObjectUrl } from "@project/shared"
import styled from "styled-components"

interface ImageUploadProps {
  height?: string
  width?: string
  cover?: boolean
  isDefaultImage?: boolean
  onChange?: (fileName?: any, value?: any) => void
  loading?: boolean
  value?: any
  model?: string
  showLoading?: boolean
  type?: string
  name?: string
  setIsFileLoading?: React.Dispatch<React.SetStateAction<boolean>>
  data?: any
}

const UploadButtonWrapper = styled.div`
  .upload-btn {
    border-radius: 10px;
    display: flex;
    height: 54px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
`

const FileUpload: React.FC<ImageUploadProps> = (props) => {
  const {
    cover = false,
    width,
    value,
    onChange,
    type,
    model,
    name,
    setIsFileLoading,
    data,
  } = props

  const [file, setFile] = useState(null)
  //   const [imgUrl, setImgUrl] = useState(null)
  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation("fileUpload", fileUpload, {
    onSuccess: (result) => {
      setIsFileLoading(false)
      if (type === "pdf") {
        onChange && onChange(file?.name, result?.data)
        setFile({
          url: result?.data,
          ...file,
        })
      } else {
        onChange && onChange(null, result?.data)
        setFile({
          url: result?.data,
          alt: cover ? "Cover image " : "Thumb ",
        })
      }
    },
    onError: (error?: any) => {
      setIsFileLoading(false)
      const msg = error.data.error
      notification.error({
        message: msg ? t(`${msg}`) : t("An error has occurred"),
      })
    },
  })

  useEffect(() => {
    setFile({
      url: value,
      alt: value && cover ? "Cover image " : "Thumb ",
    })
    return () => {
      setFile(null)
    }
  }, [value])

  //   const handleDeleteClick = () => {
  //     setFile(null)
  //     onChange && onChange(null, null)
  //   }

  const beforeUpload = (file) => {
    setIsFileLoading(true)
    const isJpgOrPngorPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf" ||
      file.type === ".doc" ||
      file.type === ".docx" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "image/jpg"

    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M || !isJpgOrPngorPdf) {
      notification.error({
        message: t(
          "画像は jpeg, png, jpg, pdf, doc, docx 形式の5mb以下のファイル1枚のみアップロードできます"
        ),
      })
      setIsFileLoading(false)
      return false
    }
    return true
  }

  const handleChange = async (info) => {
    if (info.file.status === "done") {
      const bodyFormData = new FormData()
      bodyFormData.append("file", info?.file?.originFileObj)
      bodyFormData.append("model", model ? model : "default")
      if (type === "pdf") {
        setFile({ name: info?.file?.originFileObj?.name })
      }
      mutate(bodyFormData)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (file?.url?.path) {
        const imageUrl = await getSignedObjectUrl(file?.url?.path)
        // setImgUrl(imageUrl)
        return imageUrl
      }
    })()
    // return () => {
    //   //   setImgUrl(null)
    //   return imageUrl
    // }
  }, [file?.url])

  if (isLoading) {
    return <></>
  }
  return (
    <Upload
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      accept={
        ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpg, image/jpeg, image/png,application/pdf"
      }
      name={name}
    >
      {data === "" && (
        <UploadButtonWrapper>
          <Button
            className="upload-btn"
            width={width ? width : "auto"}
            height="54px"
            background={"#fff"}
            color="#313131"
            bordercolor="#E26E30"
            onClick={onChange}
            // loading={isLoading}
            htmlType="submit"
          >
            <PlusOutlined style={{ color: "#E26E30" }} />
            {t("Upload")}
          </Button>
        </UploadButtonWrapper>
      )}
    </Upload>
  )
}

export { FileUpload }
