import { API } from "@project/shared"

export interface IReturnAdmin {
  count?: number
  data?: any
}

interface IAdminQueryParam {
  page?: string
  pageSize?: string
  name?: string
  email?: string
  admin_type?: string
  ip_unregistered?: boolean
}

export const fetchAdmin = async ({ queryKey }): Promise<IReturnAdmin> => {
  const params = {} as IAdminQueryParam
  params.page = queryKey[1].page
  params.pageSize = queryKey[1].pageSize
  params.name = queryKey[1].name
  params.email = queryKey[1].email
  params.admin_type = queryKey[1].admin_type
  params.ip_unregistered = queryKey[1].ip_unregistered
  return API.get(`/admins`, {
    params,
  })
}

export const getAdmin = async (id) => {
  return API.get(`/admins/${id}`)
}

export const createAdmin = async (values): Promise<any> => {
  return API.post(`/admins`, values)
}

export const updateAdmin = async (values) => {
  const id = values.id
  return API.put(`/admins/${id}`, values)
}
