import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import styled from "styled-components"
import {
  TextField,
  Button,
  theme,
  Select,
  Loader,
  Content,
  TitleWrapper,
  HalfDiv,
  Required,
  PasswordInputBox,
  PhoneDiv,
  FullDiv,
  ButtonWrapper,
} from "@project/shared"
import { useContext, useEffect } from "react"
import { Select as AntSelect, notification } from "antd"
import { createAdmin, getAdmin, updateAdmin } from "../../../services"
import { useMutation, useQuery } from "react-query"
import { useRouter } from "next/router"
import { AuthContext } from "../../../utils"
const { Option } = AntSelect

interface Props {
  id?: string | string[]
}
const LoaderWrapper = styled.div`
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const AdminForm: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { user: currentUser } = useContext(AuthContext)

  const {
    data: adminData,
    isLoading,
    isFetching,
  } = useQuery<any, Error>(["family-edit", id], () => getAdmin(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    enabled: !!id,
    select: ({ data }) => {
      return {
        id: id,
        name: data.name,
        email: data.email,
        password: data.password,
        phone: data.phone,
        address: data.address,
        department: data.department,
        admin_type: data.admin_type,
        is_enabled: data.is_enabled,
        fid: data.firebase_uid,
      }
    },
  })

  const initialValues = adminData || {
    name: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    department: "",
    admin_type: "system-admin",
    is_enabled: 1,
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("Please enter name")),
    email: yup
      .string()
      .email(t("Email address format is incorrect."))
      .required(t("Please enter email")),
    phone: yup
      .string()
      .matches(/^\d*$/, t("Phone numbers can only be number"))
      .matches(/^(070|080|090|98|97|96)\d{8}$/, t("Phone number is incorrect")), // only nepalese and japanese number validation
    password: id
      ? yup
          .string()
          .min(6, t("Password must be atleast 6 characters"))
          .nullable()
      : yup
          .string()
          .min(6, t("Password must be atleast 6 characters"))
          .required(t("Please enter password")),
    is_enabled: yup.string(),
    address: yup.string(),
    department: yup.string(),
    type: yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate(values)
    },
  })

  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])

  const {
    mutate,
    isLoading: isMutating,
    // isSuccess,
  } = useMutation(id ? updateAdmin : createAdmin, {
    onSuccess: () => {
      notification.success({
        message: id
          ? t("Admin information updated successfully")
          : t("Admin information added successfully"),
      })
      router.back()
    },
    onError: (error?: any) => {
      if (
        error?.data?.error?.error?.includes(
          id
            ? "Couldn't update firebase user, email already exists"
            : "Email already exists"
        )
      ) {
        notification.error({
          message: t("The email address is already used."),
        })
      } else {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      }
    },
  })
  if (isLoading || isFetching) {
    return (
      <LoaderWrapper>
        <Loader size="large" />
      </LoaderWrapper>
    )
  }
  return (
    <>
      <Content>
        <TitleWrapper>{id ? t("Edit Admin") : t("Add Admin")}</TitleWrapper>
        <div className="div-wrapper">
          <HalfDiv>
            <div className="label">
              {t("Admin name")}
              <Required>{"*"}</Required>
            </div>
            <TextField
              width="100%"
              height="44px"
              className="input"
              name="name"
              disableboxshadow
              borderradius="10px"
              maxLength={50}
              showCounting
              onChange={formik.handleChange}
              value={formik?.values?.name}
              error={formik.touched.name && formik.errors?.name}
            />
          </HalfDiv>
          <HalfDiv
            isError={
              formik?.touched?.phone && formik.errors?.phone ? true : false
            }
          >
            <div className="label">
              {t("Email")}
              <Required>{"*"}</Required>
            </div>
            <TextField
              width="100%"
              height="44px"
              className="input"
              name="email"
              disableboxshadow
              borderradius="10px"
              onChange={formik.handleChange}
              value={formik?.values?.email}
              error={formik.touched.email && formik.errors?.email}
            />
          </HalfDiv>
        </div>
        <div className={"div-wrapper"}>
          <HalfDiv>
            <div className="label">
              {t("Password")} <Required>{"*"}</Required>
            </div>
            <PasswordInputBox>
              <TextField
                width={"100%"}
                height="44px"
                name="password"
                className="input"
                borderradius="10px"
                type="password"
                noPasswordIconText
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : id
                    ? null
                    : t("Please enter at least 6 characters")
                }
                disableboxshadow
              />
              <div className="note">
                {id ? t("Only edit if you want to update the password") : ""}
              </div>
            </PasswordInputBox>
          </HalfDiv>
          <PhoneDiv>
            <div
              className={
                formik.touched.phone && formik.errors.phone
                  ? "phone-error-wrapper"
                  : "phone-wrapper"
              }
            >
              <div className="label">{t("Phone number")}</div>
              <TextField
                width={"100%"}
                height="44px"
                className="input"
                name="phone"
                disableboxshadow
                borderradius="10px"
                // suffix={<PencilIcon />}
                maxLength={11}
                showCounting
                onChange={formik.handleChange}
                value={formik?.values?.phone}
                error={formik.touched.phone && formik.errors?.phone}
              />
            </div>
          </PhoneDiv>
        </div>
        <div className="div-wrapper">
          <FullDiv style={{ marginTop: "20px" }}>
            <div className="label">{t("Address")}</div>
            <TextField
              width="100%"
              height="44px"
              className="input"
              name="address"
              disableboxshadow
              showCounting
              borderradius="10px"
              maxLength={100}
              onChange={formik.handleChange}
              value={formik?.values?.address}
              error={formik.touched.address && formik.errors?.address}
            />
          </FullDiv>
        </div>
        <div className="div-wrapper">
          <HalfDiv>
            <div className="label">{t("Department")}</div>
            <TextField
              width="100%"
              height="44px"
              className="input"
              name="department"
              disableboxshadow
              borderradius="10px"
              maxLength={50}
              showCounting
              onChange={formik.handleChange}
              value={formik?.values?.department}
              error={formik.touched.department && formik.errors?.department}
            />
          </HalfDiv>
          <HalfDiv>
            <div className="label">
              {t("Admin Type")}
              <Required>{"*"}</Required>
            </div>
            <Select
              value={formik?.values.admin_type}
              className="select-component"
              onChange={(value) => {
                formik.setFieldValue("admin_type", value)
              }}
              disabled={!!id || adminData?.fid === currentUser?.uid}
            >
              <Option value={"system-admin"}>{t("System admin")}</Option>
              <Option value={"general-admin"}>{t("General admin")}</Option>
            </Select>
          </HalfDiv>
        </div>

        <div className="div-wrapper">
          <HalfDiv>
            <div className="label">{t("Account status")}</div>
            <Select
              value={formik.values.is_enabled}
              className="select-component"
              onChange={(value) => {
                formik.setFieldValue("is_enabled", value)
              }}
              disabled={adminData?.fid === currentUser?.uid}
            >
              <Option value={1}>{t("Using")}</Option>
              <Option value={0}>{t("Suspended")}</Option>
            </Select>
          </HalfDiv>
        </div>
        <ButtonWrapper>
          <Button
            width="120px"
            height="40px"
            background={theme.button.primary}
            onClick={() => formik.handleSubmit()}
            loading={isMutating}
            htmlType="submit"
          >
            {id ? t("Update") : t("Register")}
          </Button>
        </ButtonWrapper>
      </Content>
    </>
  )
}

export { AdminForm }
