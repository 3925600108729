import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import styled from "styled-components"
import {
  TextField,
  Button,
  theme,
  Select,
  DatePicker,
  Loader,
  Content,
  TitleWrapper,
  HalfDiv,
  Required,
  FullDiv,
  ButtonWrapper,
  ConsultationMemo,
} from "@project/shared"
import { useEffect, useState } from "react"
import {
  Select as AntSelect,
  Checkbox,
  Modal,
  Radio,
  Space,
  Spin,
  notification,
} from "antd"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import {
  createConsultationMemo,
  fetchUsers,
  getConsultationMemo,
  updateConsultationMemo,
  deleteConsultationMemoFile,
} from "../../../services"
import { useMutation, useQuery } from "react-query"
import router from "next/router"
import moment from "moment"
import { FileUpload } from "../../molecules"
import { t } from "i18next"
import { proposedVenueOptions } from "../../../utils"
const { Option } = AntSelect

interface Props {
  id?: string | string[]
}

const TextWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #e26e30;
`
const TextWrapperTopMargin = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #e26e30;
  margin-top: 16px;
`

const ItemContainer = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  .label {
    min-width: 165px;
    max-width: 165px;
    font-weight: 500;
    font-size: 16px;
  }
  .input {
    flex: 1;
  }
`
const BoxWrapper = styled.div`
  margin-top: 20px;
  .top-title {
    border: 1px solid #dddcdc;
    padding: 10px;
  }
  .form-content {
    padding: 10px;
    border: 1px solid #dddcdc;
    border-top: none;
    display: flex;
    justify-content: space-between;
  }
  .no-flex {
    display: flex;
    flex-direction: column;
  }
  .dynamic-add-btn {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  .fl-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    .label {
      min-width: 160px;
      color: #313131;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .text-field {
    flex: 1;
  }
  .bg-class {
    background: #fbf9f9;
    padding: 10px;
    margin-bottom: 10px;
  }
  .fl-row-center {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  }
  .position-absolute {
    padding-left: 10px;
    cursor: pointer;
  }
  .flex-wrapper {
    padding: 0px 8px;
    margin-bottom: 16px;
    font-weight: 500;
    gap: 40px;
    display: flex;
    .flex-items {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .label {
        min-width: 120px;
        line-height: normal;
        padding-bottom: 20px;
      }
    }
    .image-section {
      display: flex;
      justify-content: flex-start !important;

      .upload-section {
        padding-top: 15px;
      }
    }
  }
`

const LoaderWrapper = styled.div`
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ModalContainer = styled.div``

const CustomModal = styled(Modal)`
  .ant-modal-body {
    padding: 0 !important;
  }
`

const ErrorStyled = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-family: ${theme.font.BUS || theme.font.NS};
  font-weight: ${theme.text.weight.regular};
  color: ${theme.alert};
  margin-top: 2px;
  margin-left: 2px;
`
const ErrorCounterWrapper = styled.div<{ isTopLess?: boolean }>`
  position: absolute;
  bottom: 0;
  top: ${(props: any) => (props.isTopLess ? "35px" : "66px")};
  left: 166px;
`

export const options = [
  { label: t("Food (Wake cuisine)"), value: "お料理(通夜料理)" },
  { label: t("Cuisine (Chinese cuisine)"), value: "お料理(忌中料理)" },
  { label: t("Return Gifts / Funeral Gifts"), value: "返礼品・会葬品" },
  { label: t("Funeral gifts"), value: "会葬御礼品" },
  { label: t("Mourning Flowers / Fresh Flowers"), value: "喪主花・生花" },
  { label: t("Fresh Flower Rank up"), value: "fresh_flower" },
  { label: t("Common flower"), value: "供花" },
  { label: t("Embalming"), value: "embalming" },
  { label: t("Hot water bath"), value: "湯灌" },
]

const farewellOptions = [
  { label: t("お別れ式"), value: "お別れ式" },
  { label: t("1日葬"), value: "1日葬" },
  { label: t("家族葬"), value: "家族葬" },
  { label: t("一般葬（白木）"), value: "general-funeral" }, //some of the previous char in jap is not understood by gbucket so change this value to general-funeral (en translation)
  { label: t("一般葬（花）"), value: "一般葬（花）" },
]

const ConsultationMemoForm: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFileLoading, setIsFileLoading] = useState(false)
  const { user_id } = router.query
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isMaterialEntered, setIsMaterialEntered] = useState(false)
  const urlRegex =
    /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]+(?:\/[^\s]*)?$/

  const prefix = `https://storage.googleapis.com/${process?.env?.NEXT_PUBLIC_GCP_STORAGE_BUCKET_NAME}/images/materials/`
  const [items, setItems] = useState([
    {
      material_title: "",
      proposal_material: "",
    },
  ]) // Initial item

  const [uploadStatus, setUploadStatus] = useState(
    Array(items.length).fill(false)
  )

  const [userQueryParms] = useState({
    name: "",
    pageSize: "infinity",
  })
  //Initial params for fetching user api

  // const onChange = async (info, index) => {
  //   const newUploadStatus = [...uploadStatus]
  //   newUploadStatus[index] = info.file.status === "done"
  //   setUploadStatus(newUploadStatus)
  //   if (info.file.status === "done") {
  //     const newItems = [...items]
  //     newItems[index]["proposal_material"] = info?.file.name
  //     setItems(newItems)
  //     if (newItems?.length > 0) {
  //       formik?.setFieldValue("material", newItems)
  //     }
  //   }
  // }

  const customRemove = async (index) => {
    const fileUrl = material?.[index]?.proposal_material
    if (fileUrl?.startsWith(prefix)) {
      await deleteConsultationFile({ object: fileUrl })
    }

    const newUploadStatus = [...uploadStatus]
    newUploadStatus[index] = false
    setUploadStatus(newUploadStatus)
  }

  const {
    data: userData,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = useQuery(["fetchUsers", userQueryParms], fetchUsers, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    select: (response) => {
      return {
        data: response?.data?.map((data) => {
          return {
            key: data.id,
            id: data.id,
            name: data.name,
            phone: data.phone,
          }
        }),
        count: response?.count,
      }
    },
  })
  //options for userName [Dropdown]
  const userOptions = userData?.data.map((user) => ({
    key: user.key,
    value: user.id,
    label: user.name,
    phone: user.phone,
  }))

  const {
    data: consultData,
    isLoading: isSingleDataLoading,
    isFetching: isSingleDataFetching,
  } = useQuery<any, Error>(
    ["consult-edit", id],
    () => getConsultationMemo(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!id,
      select: ({ data }) => {
        return {
          id: data?.id,
          user_id: data?.user_id,
          consultation_date: moment(data?.consultation_date) || null,
          denomination: data?.denomination,
          desired_option: data?.desired_option,
          farewell_ceremony: data?.farewell_ceremony,
          funeral_mourners: data?.funeral_mourners,
          funeral_relatives: data?.funeral_relatives,
          material: data?.material || [
            {
              material_title: "",
              proposal_material: "",
            },
          ],
          notice: data?.notice,
          officiant_introduction: data?.officiant_introduction,
          proposed_venue: data?.proposed_venue,
          proposed_venue_1: data?.proposed_venue_1,
          religion: data?.religion,
          sunlife_subscription: data?.sunlife_subscription,
          user_name: data?.user_name,
          hint: data?.hint,
          manager_image: data?.manager_image,
          select_officiant: data?.select_officiant,
          join_intension: data?.join_intension,
        }
      },
      onSuccess: (data) => {
        setItems(data?.material)
      },
    }
  )
  const initialValues = consultData || {
    user_id: user_id || "",
    consultation_date: moment().format("YYYY-MM-DD"),
    consultation_title: "",
    manager_image: "",
    funeral_relatives: null,
    funeral_mourners: null,
    farewell_ceremony: "",
    sunlife_subscription: 1,
    desired_option: null,
    religion: "",
    denomination: "",
    officiant_introduction: "yes",
    proposed_venue: "",
    proposed_venue_1: "",
    material: [
      {
        material_title: "",
        proposal_material: "",
      },
    ],
    notice: "",
    user_name: user_id
      ? userOptions?.find((user) => user.key === user_id)?.name
      : "",
    hint: "",
    select_officiant: "",
    join_intension: 1,
  }

  const validationSchema = yup.object().shape(
    {
      consultation_date: yup.string().nullable().required(t("Required")),
      user_id: yup.string().required(t("Please select username")),
      farewell_ceremony: yup
        .string()
        .nullable()
        .required(t("Please select farewell ceremony")),
      religion: yup
        .string()
        .required(t("Please enter religion (Enter no if you have nothing)")),
      denomination: yup
        .string()
        .required(
          t("Please select denomination (Enter no if you have nothing)")
        ),
      manager_image: yup.string().required(t("Please select PIC image")),
      proposed_venue: yup.string(),
      select_officiant: yup.string().when("officiant_introduction", {
        is: (officiant_introduction) => officiant_introduction === "yes",
        then: yup.string().required(t("Please select select officiant")),
        otherwise: yup.string(),
      }),
    },
    [["officiant_introduction", "select_officiant"]]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const funeral_relatives =
        values?.funeral_relatives === null ||
        values?.funeral_relatives?.length === 0
          ? null
          : Number(values?.funeral_relatives)
      const funeral_mourners =
        values?.funeral_mourners === null ||
        values?.funeral_mourners?.length === 0
          ? null
          : Number(values?.funeral_mourners)
      const consultation_date = moment(values?.consultation_date)?.format(
        "YYYY-MM-DD"
      )
      const desired_option =
        values?.desired_option === null || values?.desired_option?.length === 0
          ? null
          : values?.desired_option
      const material =
        (values?.material[0]?.material_title === "" &&
          values?.material[0]?.proposal_material === "") ||
        values?.material?.length === 0
          ? null
          : values?.material
      const updatedValues = {
        ...values,
        consultation_date,
        funeral_relatives,
        funeral_mourners,
        desired_option,
        material,
      }

      const areAllItemsFilled = values?.material?.every(
        (item) => item.material_title !== "" && item.proposal_material !== ""
      )
      const urlValid = values?.material?.every((item) =>
        item?.proposal_material?.startsWith(prefix)
          ? true
          : urlRegex.test(item.proposal_material)
      )
      if (values?.material?.length > 1 && areAllItemsFilled && urlValid) {
        mutate(updatedValues)
        return
      }
      mutate(updatedValues)
    },
  })
  const userSelected = user_id
    ? userOptions?.find((user) => {
        return user.key === user_id
      })
    : userOptions?.find((user) => user.key === formik.values.user_id)

  const [phone, setPhone] = useState(userSelected?.phone || "")

  const areAllItemsFilled = formik?.values?.material?.every(
    (item) => item.material_title !== "" && item.proposal_material !== ""
  )

  const urlValid = formik?.values?.material?.every((item) =>
    item?.proposal_material?.startsWith(prefix)
      ? true
      : urlRegex.test(item.proposal_material)
  )

  const handleRemoveItem = (index) => {
    const newItems = formik.values.material.filter((_, i) => i !== index)

    formik?.setFieldValue("material", newItems)
    setItems(newItems)
  }
  const handleAddItem = () => {
    const newItems = [
      ...formik?.values?.material,
      { material_title: "", proposal_material: "" },
    ]
    formik?.setFieldValue("material", newItems)
    setItems(newItems)
  }

  //Modal handlers
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])
  const { mutate, isLoading: isMutating } = useMutation(
    id ? updateConsultationMemo : createConsultationMemo,
    {
      onSuccess: () => {
        notification.success({
          message: id
            ? t("Consultation Memo updated successfully")
            : t("Consultation Memo added successfully"),
        })
        router.back()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )
  //API for deleting file
  const { mutate: deleteConsultationFile, isLoading } = useMutation(
    deleteConsultationMemoFile,
    {
      onError: (error?: any) => {
        const msg = error.data.error
        notification.error({
          message: msg
            ? t(`${msg}`)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )

  const { material } = formik?.values

  useEffect(() => {
    if (userSelected && formik.values.user_id === user_id) {
      return setPhone(userSelected?.phone)
    }
    if (id) {
      return setPhone(userSelected?.phone)
    }
  }, [formik.values.user_id, user_id, id, userOptions])

  if (
    isSingleDataLoading ||
    isSingleDataFetching ||
    isUserLoading ||
    isUserFetching
  ) {
    return (
      <LoaderWrapper>
        <Loader size="large" />
      </LoaderWrapper>
    )
  }

  return (
    <>
      <Content>
        <>
          <CustomModal
            width={"70%"}
            title={t("Consultation Preview")}
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <ModalContainer>
              <ConsultationMemo data={formik?.values} isPreview />
            </ModalContainer>
          </CustomModal>
        </>
        <TitleWrapper>{t("Basic information")}</TitleWrapper>
        <div className="div-wrapper">
          <HalfDiv>
            <div className="label">
              {t("User Name")}
              <Required>{"*"}</Required>
            </div>

            <Select
              type="consultation"
              showSearch={true}
              allowClear={true}
              options={userOptions}
              filterOption={(input, option) => {
                return option?.children
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }}
              placeholder={t("Select user name")}
              value={formik?.values?.user_id || null}
              className="select-component consultation"
              onChange={(value: string, option: any) => {
                formik.setFieldValue("user_id", value)
                formik.setFieldValue("user_name", option?.children)
                setPhone(option?.phone)
              }}
              disabled={!!id}
              error={formik.touched.user_id && formik.errors?.user_id}
            />
          </HalfDiv>
          <HalfDiv style={{ position: "relative" }}>
            <div className="label">
              {t("Consultation Date")}
              <Required>{"*"}</Required>
            </div>
            <DatePicker
              style={{ background: "#f8f5ef", height: "54px" }}
              width="100%"
              borderRadius="10px"
              allowClear
              format={"YYYY年MM月DD日"}
              value={
                formik?.values?.consultation_date != null
                  ? moment(formik?.values?.consultation_date)
                  : null
              }
              onChange={(val) => {
                formik.setFieldValue("consultation_date", val)
              }}
            />
            <ErrorCounterWrapper>
              <ErrorStyled className={"err-text"}>
                {formik.touched.consultation_date &&
                  formik.errors?.consultation_date}
              </ErrorStyled>
            </ErrorCounterWrapper>
          </HalfDiv>
        </div>
        {formik?.values?.user_id && phone && (
          <div className="div-wrapper">
            <HalfDiv>
              <div className="label">{t("Phone")}</div>
              <TextField
                width="273px"
                height="54px"
                className="input"
                name="phone"
                disableboxshadow
                borderradius="10px"
                disabled
                value={phone}
              />
            </HalfDiv>
          </div>
        )}
        <BoxWrapper>
          <div className="top-title">
            <TextWrapper>
              {t("[Funeral wishes/situation] ＊Participants predictions")}
            </TextWrapper>
          </div>
          <div className="form-content">
            <HalfDiv>
              <div className="label">{t("Relatives")}</div>
              <TextField
                width="100%"
                height="54px"
                className="input"
                name="funeral_relatives"
                placeholder={t("Enter number of relatives")}
                disableboxshadow
                borderradius="10px"
                onChange={(e) => {
                  const enteredValue = e.target.value

                  // Regex for checking alphabets
                  const validInputRegex = /^[0-9]*$/

                  if (validInputRegex.test(enteredValue)) {
                    formik.setFieldValue("funeral_relatives", enteredValue)
                  }
                }}
                value={formik?.values?.funeral_relatives}
                maxLength={5}
                showCounting
                error={
                  formik.touched.funeral_relatives &&
                  formik.errors?.funeral_relatives
                }
              />
            </HalfDiv>
            <HalfDiv>
              <div className="label">{t("Mourner")}</div>
              <TextField
                width="100%"
                height="54px"
                className="input"
                name="funeral_mourners"
                placeholder={t("Enter number of mourner")}
                disableboxshadow
                borderradius="10px"
                onChange={(e) => {
                  const enteredValue = e.target.value

                  // Regex for checking alphabets
                  const validInputRegex = /^[0-9]*$/

                  if (validInputRegex.test(enteredValue)) {
                    formik.setFieldValue("funeral_mourners", enteredValue)
                  }
                }}
                value={formik?.values?.funeral_mourners}
                maxLength={5}
                showCounting
                error={
                  formik.touched.funeral_mourners &&
                  formik.errors?.funeral_mourners
                }
              />
            </HalfDiv>
          </div>
        </BoxWrapper>
        <TextWrapperTopMargin>{t("Considerable plans")}</TextWrapperTopMargin>
        <FullDiv style={{ marginTop: "16px", position: "relative" }}>
          <div className="label">
            {t("Farewell\nCeremony")}
            <Required>{"*"}</Required>
          </div>

          {farewellOptions?.map((option) => (
            <Checkbox
              key={option.value}
              checked={formik?.values?.farewell_ceremony === option.value}
              onChange={(e) => {
                formik.setFieldValue(
                  "farewell_ceremony",
                  e.target.checked ? option.value : null
                )
              }}
            >
              {option.label}
            </Checkbox>
          ))}
          <ErrorCounterWrapper isTopLess>
            <ErrorStyled className={"err-text"}>
              {formik.touched.farewell_ceremony &&
                formik.errors?.farewell_ceremony}
            </ErrorStyled>
          </ErrorCounterWrapper>
        </FullDiv>
        <div
          className="div-wrapper"
          style={{ marginTop: "30px", marginBottom: "16px" }}
        >
          <HalfDiv>
            <div className="label">{t("Subscribed to Sun\nLife Members")}</div>
            <Radio.Group
              value={formik.values.sunlife_subscription}
              onChange={(evt) => {
                formik.setFieldValue("sunlife_subscription", evt.target.value)
              }}
            >
              <Radio value={1}>{t("Already")}</Radio>
              <Radio value={0}>{t("Not Yet")}</Radio>
            </Radio.Group>
          </HalfDiv>
          <HalfDiv>
            <div className="label">{t("Join intension")}</div>
            <Radio.Group
              value={formik.values.join_intension}
              onChange={(evt) => {
                formik.setFieldValue("join_intension", evt.target.value)
              }}
            >
              <Radio value={1}>{t("あり")}</Radio>
              <Radio value={0}>{t("なし")}</Radio>
              <Radio value={2}>{t("Already joined")}</Radio>
            </Radio.Group>
          </HalfDiv>
        </div>
        <div className="div-wrapper">
          <HalfDiv>
            <div className="label">
              {t("Proposed venue\n ①")}
              {/* <Required>{"*"}</Required> */}
            </div>
            <Select
              showSearch={true}
              options={proposedVenueOptions}
              allowClear={true}
              type="consultation"
              placeholder={t("Select proposed venue ①")}
              value={formik?.values.proposed_venue || null}
              filterOption={(input, option) => {
                return option?.children
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }}
              className="select-component consultation"
              onChange={(value) => {
                formik.setFieldValue("proposed_venue", value)
              }}
              error={formik.errors?.proposed_venue}
            />
          </HalfDiv>
          <HalfDiv>
            <div className="label">{t("Proposed venue\n ②")}</div>
            <Select
              showSearch={true}
              options={proposedVenueOptions}
              allowClear={true}
              type="consultation"
              filterOption={(input, option) => {
                return option?.children
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }}
              placeholder={t("Select proposed venue ②")}
              value={formik?.values.proposed_venue_1 || null}
              className="select-component consultation"
              onChange={(value) => {
                formik.setFieldValue("proposed_venue_1", value)
              }}
              error={
                formik.touched.proposed_venue_1 &&
                formik.errors?.proposed_venue_1
              }
            />
          </HalfDiv>
        </div>
        <TextWrapper>{t("Desired Option")}</TextWrapper>
        <div className="div-wrapper">
          <FullDiv style={{ marginTop: "16px" }}>
            <div className="label">{t("Select Desired\nOption")}</div>
            <Checkbox.Group
              options={options}
              onChange={(val) => formik.setFieldValue("desired_option", val)}
              value={formik?.values?.desired_option}
            />
          </FullDiv>
        </div>
        <TextWrapperTopMargin>{t("Others")}</TextWrapperTopMargin>
        <div className="div-wrapper">
          <HalfDiv>
            <div className="label">
              {t("Religion")} <Required>{"*"}</Required>
            </div>

            <TextField
              width="100%"
              height="54px"
              className="input"
              name="religion"
              placeholder={t("Enter you religion")}
              disableboxshadow
              borderradius="10px"
              onChange={formik.handleChange}
              value={formik?.values?.religion}
              error={formik.touched.religion && formik.errors?.religion}
              showCounting
              maxLength={10}
            />
          </HalfDiv>
          <HalfDiv>
            <div className="label">
              {t("Denomination")}
              <Required>{"*"}</Required>
            </div>
            <TextField
              width="100%"
              height="54px"
              className="input"
              name="denomination"
              placeholder={t("Enter you denomination")}
              disableboxshadow
              borderradius="10px"
              onChange={formik.handleChange}
              value={formik?.values?.denomination}
              error={formik.touched.denomination && formik.errors?.denomination}
              showCounting
              maxLength={10}
            />
          </HalfDiv>
        </div>
        <div className="div-wrapper">
          <HalfDiv>
            <div className="label">{t("Introduction of the\nofficiant")}</div>
            <Radio.Group
              value={formik.values.officiant_introduction}
              onChange={(evt) => {
                formik.setFieldValue("officiant_introduction", evt.target.value)
              }}
            >
              <Radio value={"yes"}>{t("Hope")}</Radio>
              <Radio value={"no"}>{t("No hope")}</Radio>
              <Radio value={"undecided"}>{t("Undecided")}</Radio>
            </Radio.Group>
          </HalfDiv>
          <HalfDiv>
            <div className="label">
              {t("Select Officiant")}
              {formik.values.officiant_introduction === "yes" && (
                <Required>*</Required>
              )}
            </div>
            <div className="custom-select-width345">
              <Select
                placeholder={t("Select Officiant")}
                value={formik?.values.select_officiant || null}
                className="select-component consultation"
                onChange={(value) => {
                  formik.setFieldValue("select_officiant", value)
                }}
                error={
                  (formik.touched.officiant_introduction ||
                    formik.touched.select_officiant) &&
                  formik.errors?.select_officiant
                }
                allowClear
              >
                <Option value={"表示しない"}>{"表示しない"}</Option>
                <Option value={"荼毘経"}>{"荼毘経"}</Option>
                <Option value={"読経1日"}>{"読経1日"}</Option>
                <Option value={"読経2日"}>{"読経2日"}</Option>
                <Option value={"無宗教"}>{"無宗教"}</Option>
                <Option value={"無宗教式"}>{"無宗教式"}</Option>
              </Select>
            </div>
          </HalfDiv>
        </div>
        <FullDiv>
          <div className="label" style={{ paddingBottom: "20px" }}>
            {t("Hint")}
          </div>
          <TextField
            width="100%"
            height="54px"
            className="input"
            maxLength={100}
            showCounting
            name="hint"
            placeholder={t("Enter your hint here")}
            disableboxshadow
            borderradius="10px"
            onChange={formik.handleChange}
            value={formik?.values?.hint}
            error={formik.touched.hint && formik.errors?.hint}
          />
        </FullDiv>
        <TextWrapper>{t("Person incharge")}</TextWrapper>
        <HalfDiv style={{ marginTop: "16px" }}>
          <div style={{ paddingTop: "15px" }} className="label">
            {t("Select PIC image")}
            <Required>{"*"}</Required>
          </div>
          <Select
            width={375}
            value={formik?.values.manager_image || null}
            allowClear
            className="select-component consultation"
            onChange={(value) => {
              formik.setFieldValue("manager_image", value)
            }}
            error={isSubmitted && formik.errors?.manager_image}
          >
            <Option value={"中島大輔"}>{"中島大輔"}</Option>
            <Option value={"佐々木佑夏"}>{"佐々木佑夏"}</Option>
            <Option value={"小野貴章"}>{"小野貴章"}</Option>
            <Option value={"岡本未来"}>{"岡本未来"}</Option>
            <Option value={"梅田萌香"}>{"梅田萌香"}</Option>
            <Option value={"河副豊"}>{"河副豊"}</Option>
            <Option value={"片岡玲子"}>{"片岡玲子"}</Option>
            <Option value={"西陽子"}>{"西陽子"}</Option>
            <Option value={"担当者なし"}>{"担当者なし"}</Option>
          </Select>
        </HalfDiv>
        <BoxWrapper>
          <div className="top-title">
            <TextWrapper>{t("Material uploads")}</TextWrapper>
          </div>
          <div className="form-items form-content no-flex">
            {items?.map((item, index) => (
              <div key={index}>
                <div className="bg-class">
                  <div className="fl-row">
                    <div
                      className="label"
                      style={
                        formik.touched.material &&
                        formik.values.material[index]?.material_title === ""
                          ? { marginTop: "-22px" }
                          : { marginTop: null }
                      }
                    >
                      {t("Title")}
                    </div>
                    <div className="text-field">
                      <TextField
                        placeholder={t("Enter Proposal title")}
                        width="100%"
                        height="54px"
                        className="input"
                        name="material_title"
                        maxLength={100}
                        showCounting
                        disableboxshadow
                        borderradius="10px"
                        onChange={(e) =>
                          formik.setFieldValue(
                            `material.[${index}].material_title`,
                            e.target.value
                          )
                        }
                        value={formik.values.material[index]?.material_title}
                      />
                    </div>
                    {isFileLoading && index + 1 == items?.length ? (
                      <Spin
                        style={{ marginLeft: "5px" }}
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    ) : (
                      <Space
                        style={
                          formik.touched.material &&
                          formik.values.material[index]?.material_title === ""
                            ? { marginTop: "-25px" }
                            : { marginTop: "-22px" }
                        }
                        className="position-absolute"
                        onClick={() => {
                          handleRemoveItem(index)
                          !isLoading && customRemove(index)
                        }}
                      >
                        <img
                          src="/assets/icons/deleteIcon.svg"
                          width={20}
                          height={20}
                          alt="delete"
                        />
                      </Space>
                    )}
                  </div>
                  <div className="fl-row">
                    <div
                      className="label"
                      style={
                        formik.touched.material &&
                        formik.errors.material?.[index]?.proposal_material
                          ? { marginTop: "-20px" }
                          : { marginTop: "-30px" }
                      }
                    >
                      {t("Proposal Material")}
                    </div>
                    <div className="text-field">
                      <div className="fl-row-center">
                        <TextField
                          placeholder={t("Enter URL ")}
                          width="100%"
                          height="54px"
                          className="input"
                          name={"proposal_material"}
                          disableboxshadow
                          borderradius="10px"
                          onChange={(e) => {
                            setIsMaterialEntered(true)
                            formik.setFieldValue(
                              `material.[${index}].proposal_material`,
                              e.target.value
                            )
                          }}
                          disabled={
                            uploadStatus[index] ||
                            material?.[index]?.proposal_material?.startsWith(
                              prefix
                            )
                          }
                          value={
                            material?.[index]?.proposal_material?.startsWith(
                              prefix
                            )
                              ? material?.[index]?.proposal_material.split(
                                  "-KO|"
                                )[1]
                              : material?.[index]?.proposal_material
                          }
                          error={
                            (formik.touched.material || isMaterialEntered) &&
                            (formik.values.material[index]
                              ?.proposal_material === "" ||
                              !urlRegex.test(
                                formik.values.material[index]?.proposal_material
                              )) &&
                            !isFileLoading
                              ? `${
                                  formik.values.material[index]
                                    ?.proposal_material === ""
                                    ? t("")
                                    : formik?.values.material[
                                        index
                                      ]?.proposal_material?.startsWith(prefix)
                                    ? ""
                                    : t("Please enter valid URL")
                                }`
                              : ""
                          }
                        />
                        <div
                          style={
                            isFileLoading
                              ? { paddingLeft: "20px" }
                              : { paddingLeft: "10px" }
                          }
                        ></div>
                        <div
                          style={
                            formik.touched.material &&
                            formik.values.material[index]?.proposal_material ===
                              ""
                              ? { marginTop: "-26px" }
                              : { marginTop: null }
                          }
                        >
                          <FileUpload
                            name={"proposal_material"}
                            onChange={(_, name) => {
                              if (name?.success) {
                                const newUploadStatus = [...uploadStatus]
                                newUploadStatus[index] = name?.success === true
                                setUploadStatus(newUploadStatus)

                                formik.setFieldValue(
                                  `material.[${index}].proposal_material`,
                                  name?.path
                                )
                              } else {
                                formik.setFieldValue(
                                  `material.[${index}].proposal_material`,
                                  ""
                                )
                              }
                            }}
                            data={material?.[index]?.proposal_material || ""}
                            setIsFileLoading={setIsFileLoading}
                          />
                        </div>
                        {/* <div>
                          {material?.[index]?.proposal_material !== "" &&
                            !isFileLoading && (
                              <>
                                {material?.[index]?.proposal_material ===
                                undefined ? null : (
                                  <Space
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      !isLoading && customRemove(index)
                                    }
                                  >
                                    <img
                                      src="/assets/icons/deleteIcon.svg"
                                      width={20}
                                      height={20}
                                      alt="delete"
                                    />
                                  </Space>
                                )}
                              </>
                            )}
                        </div> */}
                      </div>
                      <div>{t("*Either Add URL or upload file")}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {areAllItemsFilled && urlValid && (
              <div className="dynamic-add-btn">
                <Button
                  width="auto"
                  height="40px"
                  background={"#fff"}
                  color="#E26E30"
                  bordercolor="#E26E30"
                  onClick={handleAddItem}
                  // loading={isLoading}
                  htmlType="submit"
                >
                  <PlusOutlined />
                  {t("Add field")}
                </Button>
              </div>
            )}
          </div>
        </BoxWrapper>
        <ItemContainer>
          <div className="label">{t("Notice")}</div>

          <TextField
            type="textarea"
            width="100%"
            height="90px"
            name="notice"
            rows={3}
            value={formik?.values?.notice}
            onChange={formik.handleChange}
            maxLength={1000}
            showCounting
            placeholder={t("Enter Notice text")}
            disableboxshadow
            borderradius="10px"
            error={formik.touched.notice && formik.errors?.notice}
          />
        </ItemContainer>
        <ButtonWrapper justifyContent="space-between">
          <Button
            width="auto"
            height="40px"
            background={"#fff"}
            color="#E26E30"
            bordercolor="#E26E30"
            onClick={showModal}
            className="preview-btn"
          >
            {t("Preview memo")}
          </Button>
          <Button
            width="120px"
            height="40px"
            background={theme.button.primary}
            onClick={() => {
              formik.handleSubmit(), setIsSubmitted(true)
            }}
            loading={isMutating}
            htmlType="submit"
          >
            {id ? t("Update") : t("Add")}
          </Button>
        </ButtonWrapper>
      </Content>
    </>
  )
}

export { ConsultationMemoForm }
