import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import {
  TextField,
  Button,
  theme,
  DatePicker,
  Content,
  TitleWrapper,
  Required,
  HalfDiv,
  PhoneDiv,
  PasswordInputBox,
  FullDiv,
  MemoWrapper,
  MemoLabelWrapper,
  ButtonWrapper,
  Loader,
  dateFormat,
  dateTimeFormat,
} from "@project/shared"
import { useContext, useEffect } from "react"
import { Radio, notification } from "antd"
import { useMutation, useQuery } from "react-query"
import {
  createMember,
  getUserDetail,
  updateUserDetail,
} from "../../../services/user"
import styled from "styled-components"
import { useRouter } from "next/router"
import { AuthContext } from "../../../utils"
import moment from "moment"

interface Props {
  id?: string | string[]
}

const LoaderWrapper = styled.div`
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const MemberEditForm: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { adminRole } = useContext(AuthContext)

  const {
    data: userData,
    isLoading: userDetailLoading,
    isFetching: userDetailFetching,
  } = useQuery<any, Error>(["member", id], () => getUserDetail(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    enabled: !!id,
    select: (response) => {
      return {
        id: response?.data?.id,
        admin_id: response?.data?.admin_id,
        name: response?.data?.name,
        kana: response?.data?.kana,
        email: response?.data?.email,
        password: response?.data?.password,
        phone: response?.data?.phone,
        emergency_contact: response?.data?.emergency_contact,
        date_of_birth:
          response?.data?.date_of_birth != ""
            ? moment(response?.data?.date_of_birth)
            : null,
        address: response?.data?.address,
        gender: response?.data?.gender,
        memo: response?.data?.memo,
        is_active: response?.data?.is_active,
        created_at: response?.data?.created_at,
        last_logged_in: response?.data?.last_logged_in,
      }
    },
  })

  const initialValues = userData || {
    name: "",
    kana: "",
    email: "",
    password: "",
    phone: "",
    emergency_contact: "",
    date_of_birth: null,
    address: "",
    gender: "male",
    memo: "",
    is_active: "",
  }

  const validationSchema = yup.object().shape(
    {
      name: yup.string().required(t("Required")),
      kana: yup
        .string()
        .required(t("Please enter kana"))
        .matches(/^[ァ-ヶー\s]+$/, t("Kana format is incorrect")),
      email: yup
        .string()
        .email(t("Email address format is incorrect."))
        .when("phone", {
          is: (phone) => !phone || phone.length === 0,
          then: yup.string().nullable().required(t("Required")),
          otherwise: yup.string().nullable(),
        }),
      phone: yup
        .string()
        .matches(/^\d*$/, t("Phone numbers can only be number"))
        .matches(
          /^(070|080|090|98|97|96)\d{8}$/,
          t("Phone number is incorrect")
        ) // only nepalese and japanese number validation
        .when("email", {
          is: (email) => !email || email.length === 0,
          then: yup.string().nullable().required(t("Please enter phone")),
          otherwise: yup.string(),
        }),

      password: id
        ? yup
            .string()
            .min(6, t("Please enter at least 6 characters"))
            .nullable()
        : yup
            .string()
            .min(6, t("Please enter at least 6 characters"))
            .required(t("Please enter password")),
      address: yup.string(),
      memo: yup.string(),
      emergency_contact: yup.string(),
      gender: yup.string(),
    },
    [["phone", "email"]]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate(values)
    },
  })

  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])

  const { mutate, isLoading } = useMutation(
    id ? updateUserDetail : createMember,
    {
      onSuccess: () => {
        notification.success({
          message: id
            ? t("Member information updated successfully")
            : t("Member created successfully"),
        })
        router.back()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )
  if (userDetailFetching || userDetailLoading) {
    return (
      <LoaderWrapper>
        <Loader size="large" />
      </LoaderWrapper>
    )
  }

  return (
    <Content>
      <TitleWrapper>
        {id ? t("Edit Member") : t("Register New Member")}
      </TitleWrapper>
      <div className="div-wrapper">
        <HalfDiv>
          <div className="label">
            {t("Name")}
            <Required>{"*"}</Required>
          </div>
          <TextField
            width="100%"
            height="44px"
            className="input"
            name="name"
            disableboxshadow
            borderradius="10px"
            maxLength={50}
            onChange={formik.handleChange}
            value={formik?.values?.name}
            error={formik.touched.name && formik.errors?.name}
          />
        </HalfDiv>
        <HalfDiv
          isError={
            formik?.values?.phone?.length > 0 && formik.errors?.phone
              ? true
              : false
          }
        >
          <div className="label">
            {t("Kana")}
            <Required>{"*"}</Required>
          </div>
          <TextField
            width="100%"
            height="44px"
            className="input"
            name="kana"
            disableboxshadow
            borderradius="10px"
            maxLength={50}
            onChange={formik.handleChange}
            value={formik?.values?.kana}
            error={formik?.touched?.kana && formik.errors?.kana}
          />
        </HalfDiv>
      </div>
      <div className={"div-wrapper"}>
        <HalfDiv>
          <div className="label">{t("Email address")}</div>
          <TextField
            width="100%"
            height="44px"
            className="input"
            name="email"
            disableboxshadow
            borderradius="10px"
            onChange={formik.handleChange}
            value={formik?.values?.email}
            error={formik?.touched?.email && formik.errors?.email}
          />
        </HalfDiv>
        <PhoneDiv>
          <div
            className={
              formik.touched.phone && formik.errors.phone
                ? "phone-error-wrapper"
                : "phone-wrapper"
            }
          >
            <div className="label">
              {t("Phone number")}
              <Required>{"*"}</Required>
            </div>
            <TextField
              width={"100%"}
              height="44px"
              className="input"
              name="phone"
              disableboxshadow
              borderradius="10px"
              onChange={formik.handleChange}
              value={formik?.values?.phone}
              error={formik?.touched?.phone && formik.errors?.phone}
            />
          </div>
        </PhoneDiv>
      </div>
      <div className="div-wrapper">
        <HalfDiv>
          <div className="label">{t("Gender")}</div>
          <Radio.Group
            value={formik.values.gender}
            onChange={(evt) => {
              formik.setFieldValue("gender", evt.target.value)
            }}
          >
            <Radio value={"male"}>{t("Male")}</Radio>
            <Radio value={"female"}>{t("Female")}</Radio>
            <Radio value={"other"}>{t("Others")}</Radio>
          </Radio.Group>
        </HalfDiv>
        <HalfDiv>
          <div className="label">{t("Emergency contact")}</div>
          <TextField
            width="100%"
            height="44px"
            className="input"
            name="emergency_contact"
            disableboxshadow
            borderradius="10px"
            maxLength={12}
            onChange={(e) => {
              const enteredValue = e.target.value

              // Regex for checking alphabets
              const validInputRegex = /^[0-9]*$/

              if (validInputRegex.test(enteredValue)) {
                formik.setFieldValue("emergency_contact", enteredValue)
              }
            }}
            value={formik?.values?.emergency_contact}
            error={
              formik.touched.emergency_contact &&
              formik.errors?.emergency_contact
            }
          />
        </HalfDiv>
      </div>
      <div className="div-wrapper">
        <HalfDiv>
          <div style={{ maxWidth: "127px" }} className="label">
            {t("DOB")}
          </div>
          <DatePicker
            width="360px"
            format={"YYYY/MM/DD"}
            value={formik.values.date_of_birth}
            onChange={(val) => formik.setFieldValue("date_of_birth", val)}
          />
        </HalfDiv>
        <HalfDiv>
          <div className="label">{t("Age")}</div>
          <TextField
            width="100%"
            height="44px"
            className="input"
            name="age"
            disableboxshadow
            borderradius="10px"
            value={
              moment(formik?.values?.date_of_birth).isValid()
                ? moment().diff(
                    moment(formik?.values?.date_of_birth),
                    "years",
                    false
                  )
                : "-"
            }
            disabled
          />
        </HalfDiv>
      </div>
      <FullDiv style={{ marginTop: "20px" }}>
        <div className="label">{t("Address")}</div>
        <TextField
          width="100%"
          height="44px"
          className="input"
          name="address"
          disableboxshadow
          borderradius="10px"
          maxLength={100}
          onChange={formik.handleChange}
          value={formik?.values?.address}
          error={formik?.values?.address.length > 0 && formik.errors?.address}
        />
      </FullDiv>
      <div className="div-wrapper">
        <HalfDiv>
          <div className="label">{t("Registration")}</div>
          <TextField
            width="100%"
            height="44px"
            className="input"
            name="age"
            disableboxshadow
            borderradius="10px"
            value={
              userData?.created_at
                ? moment(userData?.created_at).format(dateFormat)
                : "-"
            }
            disabled
          />
        </HalfDiv>
        <HalfDiv>
          <div className="label">{t("Lastlogindate&time")}</div>
          <TextField
            width="100%"
            height="44px"
            className="input"
            name="age"
            disableboxshadow
            borderradius="10px"
            value={
              userData?.last_logged_in
                ? moment(userData?.last_logged_in).format(dateTimeFormat)
                : "-"
            }
            disabled
          />
        </HalfDiv>
      </div>
      <div className="div-wrapper">
        <HalfDiv>
          <div className="label">{t("Account status")}</div>
          <Radio.Group
            value={formik?.values.is_active}
            onChange={(e) => {
              formik.setFieldValue("is_active", e.target.value)
            }}
          >
            <Radio value={1}>{t("Active")}</Radio>
            <Radio value={0}>{t("Suspended")}</Radio>
          </Radio.Group>
        </HalfDiv>
        <HalfDiv>
          {adminRole !== "general-admin" && (
            <>
              <div className="label">
                {t("Password")}
                <Required>{"*"}</Required>
              </div>
              <PasswordInputBox>
                <TextField
                  width={"100%"}
                  height="44px"
                  name="password"
                  className="input"
                  borderradius="10px"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={
                    formik?.values?.password?.length > 0 &&
                    formik.errors.password
                      ? formik.errors.password
                      : t("Please enter at least 6 characters")
                  }
                  disableboxshadow
                />
              </PasswordInputBox>
            </>
          )}
        </HalfDiv>
      </div>

      <TitleWrapper style={{ marginTop: "10px" }}>
        {t("Other information")}
      </TitleWrapper>
      <MemoWrapper>
        <MemoLabelWrapper>
          <span>{t("Memo")}</span>
          <span>{`(${t("Free entry")})`}</span>
        </MemoLabelWrapper>
        <TextField
          maxLength={1000}
          showCounting
          type="textarea"
          width="100%"
          height="128px"
          name="memo"
          rows={4}
          value={formik?.values?.memo}
          onChange={formik.handleChange}
          placeholder={t("Please enter text here")}
          disableboxshadow
        />
      </MemoWrapper>
      <ButtonWrapper>
        <Button
          width="120px"
          height="40px"
          background={theme.button.primary}
          onClick={() => formik.handleSubmit()}
          loading={isLoading}
          htmlType="submit"
        >
          {id ? t("Update") : t("Save")}
        </Button>
      </ButtonWrapper>
    </Content>
  )
}

export { MemberEditForm }
